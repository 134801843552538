import { Spinner, Container, Row, Col } from "react-bootstrap";

const Loader = () => {
  return (
    <Container style={{ paddingTop: "3%", paddingBottom: "3%" }}>
      <Row className="justify-content-md-center">
        <Col xs lg="2" style={{ textAlign: "center" }}>
          <Spinner animation="grow" variant="secondary" />
        </Col>
      </Row>
    </Container>
  );
};

export default Loader;
