import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { companyService } from "../../Services/CompanyService";
import { documentService } from "../../Services/DocumentService";
import { documentAttachmentService } from "../../Services/DocumentAttachmentService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import { staticUrl } from "../../Config/Url";
import ResultModal from "../Common/ResultModal";
import { ListGroup } from "react-bootstrap";
import { roles } from "../../Config/Roles";
import { AuthContext } from "../../Contexts/AuthContext";
const DocumentUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [data, setData] = useState(null);
  const [files, setFiles] = useState([]);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;

  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });

  useEffect(() => {
    Promise.all([
      documentService.getById(match.params.id),
      companyService.getCompanies(),
      documentAttachmentService.getDocumentAttachments(match.params.id),
    ])
      .then((res) => {
        setData(res[0].data.data);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
        // console.log(res[2].data.data);
        setFiles(res[2].data.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const onSubmit = (data) => {
    documentService
      .updateDocument(match.params.id, data)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("DocumentUpdateHeader")}>
      {companies.length === 0 || data === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("DocumentFormNumber")}</label>
              <input
                name="documentNumber"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.documentNumber}
              />
              {errors.name && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("DocumentFormDescription")}</label>
              <input
                name="description"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.description}
              />
              {errors.regNum && <ErrorMsg text={translator("RequiredField")} />}
            </div>

            <div className="form-group">
              <label>{translator("DocumentFormAttachment")}</label>
              <input
                className="form-control"
                type="file"
                name="files"
                ref={register()}
                multiple
              />
            </div>
          </div>
          <div className="col-lg-8">
            <label>{translator("DocumentFormFiles")}</label>
            {files.length === 0 || data === null ? (
              ""
            ) : (
              <div>
                <ListGroup>
                  {files.map((item, i) => {
                    // Return the element. Also pass key
                    return (
                      <ListGroup.Item
                        action
                        href={`${staticUrl}/documents/${item.name}`}
                      >
                        {item.name}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            )}
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            {[superadmin, admin].includes(Number(roleId)) ? (
              <button className="btn btn-primary" type="submit">
                {translator("ButtonSave")}
              </button>
            ) : null}
          </div>
        </form>
      )}
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/documents")}
      />
    </CardWrap>
  );
};

export default DocumentUpdate;
