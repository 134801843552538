import { blackMarketUrl } from "../Config/Url";
import axios from "axios";

export const blackMarketService = {
  getblackMarkets,
  getblackMarketById,
  deleteblackMarket,
  addblackMarket,
  updateblackMarket,
};

async function getblackMarkets() {
  return axios
    .get(blackMarketUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getblackMarketById(id) {
  return axios
    .get(`${blackMarketUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteblackMarket(id) {
  return axios
    .delete(`${blackMarketUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addblackMarket({ companyId, amount, invoiceDate }) {
  return axios
    .post(blackMarketUrl, {
      companyId,
      amount,
      invoiceDate,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateblackMarket({ id, amount, invoiceDate }) {
  return axios
    .put(`${blackMarketUrl}/${id}`, {
      amount,
      invoiceDate,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
