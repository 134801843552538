import { carDocumentUrl } from "../Config/Url";
import axios from "axios";

export const carDocumentService = {
  getCarDocuments,
  deleteCarDocument,
  addCarDocument,
};

async function getCarDocuments(id) {
  return axios
    .get(`${carDocumentUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteCarDocument(id) {
  return axios
    .delete(`${carDocumentUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addCarDocument(carId, data) {
  console.log("CR", data);
  var formData = new FormData();
  let arrayTest = Array.from(data.documents);
  arrayTest.forEach((element) => {
    formData.append("Data", element);
  });

  return axios
    .post(carDocumentUrl + "/" + carId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}
