import { companyUrl } from "../Config/Url";
import axios from "axios";

export const companyService = {
  getCompanies,
  getCompanyById,
  deleteCompany,
  addCompany,
  updateCompany,
};

async function getCompanies() {
  return axios
    .get(companyUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getCompanyById(id) {
  return axios
    .get(`${companyUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteCompany(id) {
  return axios
    .delete(`${companyUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addCompany({
  name,
  address,
  city,
  postNumber,
  customerCode,
  email,
  phone,
  userName,
  password,
  vat,
  allowedLogin,
  autoSendMail,
  country,
}) {
  return axios
    .post(companyUrl, {
      name,
      address,
      city,
      postNumber,
      customerCode,
      email,
      phone,
      userName,
      password,
      autoSendMail,
      allowedLogin,
      vat,
      country,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateCompany({
  id,
  name,
  address,
  city,
  postNumber,
  customerCode,
  email,
  phone,
  vat,
  allowedLogin,
  autoSendMail,
  country,
}) {
  return axios
    .put(`${companyUrl}/${id}`, {
      name,
      address,
      city,
      postNumber,
      customerCode,
      email,
      phone,
      autoSendMail,
      allowedLogin,
      vat,
      country,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
