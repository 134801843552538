import { useEffect, useState, useContext } from "react";
import { globalCarService } from "../../Services/GlobalCarService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import DeleteModal from "../Common/DeleteModal";
import ResultModal from "../Common/ResultModal";
import * as dateHelper from "../../Helpers/DateHelper";

const GlobalCarList = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const { isMobile } = useContext(GlobalContext);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [update, setUpdate] = useState(new Date());
  const [isArrived, setIsArrived] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    item: null,
    show: false,
  });
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [search, setSearch] = useState({
    companyId: 0,
    isArrived: "",
  });

  useEffect(() => {
    globalCarService
      .getCars(search.isArrived)
      .then((res) => {
        console.log("ga", res);
        setCars(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [update, search]);

  const handleToAdd = () => {
    history.push("/add-global-car");
  };

  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      if (e.target.classList.contains("pe-7s-trash")) {
        setDeleteModal({ item: row.id, show: true });
        return;
      }
      if (e.target.classList.contains("action2")) {
        //history.push(`/add-in-processing/${row.id}`);
        addInProcessing(row.id);
        return;
      }
      history.push(`/update-global-car/${row.id}`);
    },
  };

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="action">
        <i
          className="pe-7s-trash"
          style={{ color: "#c00", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  function linkFormatter(cell, row) {
    return (
      <a className="action2" style={{ display: "block" }}>
        Add
      </a>
    );
  }
  function successFormatter(cell, row) {
    return cell == false ? null : (
      <div className="action">
        <i
          className="pe-7s-check"
          style={{ color: "#029688", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  function successFormatter(cell, row) {
    return cell == false ? null : (
      <div className="action">
        <i
          className="pe-7s-check"
          style={{ color: "#029688", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "datePurchased",
      text: `${translator("GlobalCarTableDatePurchased")}`,
      formatter: dateFormatter,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "pruchasedFrom",
      text: `${translator("GlobalCarTablePruchasedFrom")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "lotNumber",
      text: `${translator("CarTableLotNumber")}`,
      sort: true,
    },
    {
      dataField: "regNumber",
      text: `${translator("CarTableRegNum")}`,
      sort: true,
    },
    {
      dataField: "brand",
      text: `${translator("GlobalCarTableBrend")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "model",
      text: `${translator("GlobalCarTableModel")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "colour",
      text: `${translator("GlobalCarTableColor")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "engineCode",
      text: `${translator("GlobalCarTableEngineCode")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "mileage",
      text: `${translator("GlobalCarTableMileage")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "bodyType",
      text: `${translator("GlobalCarTableBodyType")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "paintCode",
      text: `${translator("GlobalCarTablePaintCode")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "isArrived",
      formatter: successFormatter,
      text: `${translator("InvoiceFormArrived")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "id",
      text: `${translator("Action")}`,
      formatter: linkFormatter,
      hidden: Boolean(isMobile),
    },
  ];
  function dateFormatter(cell, row) {
    return dateHelper.formatUtcToDate(row.datePurchased);
  }
  function modelFormatter(cell, row) {
    return row.brand + " " + cell;
  }
  const addInProcessing = (id) => {
    globalCarService
      .addInProcessing(id)
      .then((res) => {
        setUpdate(new Date());
      })
      .catch((err) => console.log(err));
  };
  const handleSubmitDeleteModal = () => {
    globalCarService
      .deleteCar(deleteModal.item)
      .then((res) => {
        setDeleteModal({ item: null, show: false });
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            });
      })
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CarOverview")}>
      <ToolkitProvider keyField="id" data={cars} columns={columns} search>
        {(props) => (
          <>
            <SearchBar
              {...props.searchProps}
              placeholder={translator("Search")}
            />
            <div className="col-md-2">
              <input
                type="checkbox"
                name="isArrived"
                checked={isArrived}
                onChange={function (event) {
                  //console.log(event.target.checked);
                  setIsArrived(!isArrived);
                  setSearch({ ...search, isArrived: event.target.checked });

                  return event;
                }}
              />{" "}
              {translator("IsArrived")}
            </div>

            {[superadmin, admin].includes(Number(roleId)) && (
              <span style={{ float: "right" }}>
                <Button variant="primary" onClick={handleToAdd}>
                  {isMobile ? (
                    <i className="pe-7s-plus"></i>
                  ) : (
                    translator("CarAddButton")
                  )}
                </Button>
              </span>
            )}

            <hr />

            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
      <DeleteModal
        show={deleteModal.show}
        handleHide={() =>
          setDeleteModal({
            item: null,
            show: false,
          })
        }
        handleSubmit={handleSubmitDeleteModal}
      />
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => {
          setUpdate(new Date());
          setResultModal({ show: false });
        }}
      />
    </CardWrap>
  );
};

export default GlobalCarList;
