import { obligationUrl } from "../Config/Url";
import axios from "axios";

export const obligationService = {
  getObligations,
  getObligationById,
  deleteObligation,
  addObligation,
  updateObligation,
};

async function getObligations() {
  return axios
    .get(obligationUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getObligationById(id) {
  return axios
    .get(`${obligationUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteObligation(id) {
  return axios
    .delete(`${obligationUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addObligation({ companyId, totalAmount, date }) {
  return axios
    .post(obligationUrl, {
      companyId,
      totalAmount,
      date,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateObligation({ id, totalAmount, date }) {
  return axios
    .put(`${obligationUrl}/${id}`, {
      totalAmount,
      date,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
