import { set } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { staticUrl } from "../../Config/Url";
import { carImageService } from "../../Services/CarImageService";

const ImageModal = ({ show, handleHide, images, translator }) => {
  console.log(images);
  const [list, setList] = useState([]);
  //const [update, setUpdate] = useState(new Date());
  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "name",
      formatter: imageFormatter,
      text: `${translator("CarImage")}`,
    },
    {
      dataField: "name",
      //formatter: imageFormatter,
      text: `${translator("CarImage")}`,
    },
  ];
  useEffect(() => {
    setList(images);
  }, []);

  function imageFormatter(cell, row) {
    return (
      <img
        src={`${staticUrl}/images/${cell}`}
        style={{ width: 100 }}
        alt={cell}
      ></img>
    );
  }
  function actionFormatter(cell, row) {
    return (
      <div className="action">
        <i
          className="pe-7s-trash"
          style={{ color: "#c00", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }

  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      if (e.target.classList.contains("pe-7s-trash")) {
        carImageService
          .deleteCarImage(row.id)
          .then((res) => {
            res.data.success
              ? setList(list.filter((e) => e.id !== row.id))
              : //alert("ok")
                alert("Not ok");
          })
          .catch((err) => console.log(err));
        return;
      }
    },
  };
  console.log(list);
  return (
    <Modal size="lg" show={show} onHide={handleHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{translator("ImageOverview")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ToolkitProvider keyField="id" data={list} columns={columns} search>
          {(props) => (
            <>
              <BootstrapTable
                {...props.baseProps}
                //pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            </>
          )}
        </ToolkitProvider>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleHide}
        >
          {translator("ButtonCancel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
