import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { invoiceService } from "../../Services/InvoiceService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import paginationFactory from "react-bootstrap-table2-paginator";
import { companyService } from "../../Services/CompanyService";
import { convertToPdf } from "../../Services/PDFService/invoiceToPdf.service";
import { roles } from "../../Config/Roles";
import { AuthContext } from "../../Contexts/AuthContext";
import ResultModal from "../Common/ResultModal";
import cellEditFactory from "react-bootstrap-table2-editor";

const InvoiceUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const paymentTypes = [
    { value: "Credit", label: "Credit" },
    { value: "Card", label: "Card" },
    { value: "Cash", label: "Cash" },
    { value: "Bank transfer", label: "Bank transfer" },
  ];
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  useEffect(() => {
    Promise.all([
      invoiceService.getInvoice(match.params.id),
      companyService.getCompanies(),
    ])
      .then((res) => {
        setData(res[0].data.data);
        setItems(res[0].data.items);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const columns = [
    {
      dataField: "name",
      text: `${translator("Name")}`,
    },
    {
      dataField: "regNum",
      text: `${translator("RegNum")}`,
    },
    {
      dataField: "chasisNum",
      text: `${translator("ChasisNum")}`,
    },
    {
      dataField: "engineNum",
      text: `${translator("EngineNum")}`,
    },
    {
      dataField: "engineSize",
      text: `${translator("EngineSize")}`,
    },
    {
      dataField: "kw",
      text: `${translator("kW")}`,
    },
    {
      dataField: "color",
      text: `${translator("Color")}`,
    },
    {
      dataField: "lotNumber",
      text: `${translator("LotNumber")}`,
    },
    {
      dataField: "amount",
      text: `${translator("Amount")}`,
    },
    {
      dataField: "quantity",
      text: `${translator("Quantity")}`,
    },
  ];

  const columns2 = [
    {
      dataField: "comodityCode",
      text: `${translator("ComodityCode")}`,
    },
    {
      dataField: "partNumber",
      text: `${translator("PartNumber")}`,
    },
    {
      dataField: "description",
      text: `${translator("Description")}`,
    },
    {
      dataField: "referenceNumber",
      text: `${translator("ReferenceNumber")}`,
    },
    {
      dataField: "amount",
      text: `${translator("Amount")}`,
    },
    {
      dataField: "quantity",
      text: `${translator("Quantity")}`,
    },
  ];
  const onSubmit = (data) => {
    console.log(items);
    invoiceService
      .updateInvoice(match.params.id, data, items)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err.response));
  };

  const exportToPdf = (data, items, sendMail) => {
    //let base64QR = document.getElementById("qrCode").toDataURL();
    convertToPdf.handlePrint(data, items, sendMail, translator);
  };
  return (
    <CardWrap title={translator("InvoceUpdateHeader")}>
      {companies.length === 0 || data === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label>{translator("InvoiceFormNumber")}</label>
              <input
                type="number"
                name="invoiceNumber"
                ref={register({ required: true })}
                defaultValue={data.invoiceNumber}
                className="form-control"
              />
              {errors.invoiceNumber && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormDate")}</label>
              <Controller
                as={DatePicker}
                control={control}
                className="form-control"
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="invoiceDate"
                rules={{ required: true }}
                defaultValue={new Date(data.invoiceDate)}
              />
              {errors.invoiceDate && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormCompany")}</label>
              <Controller
                as={Select}
                control={control}
                options={companies}
                rules={{ required: true }}
                name="companyId"
                placeholder={translator("CompanyDropdownOption")}
                defaultValue={{
                  label:
                    companies.length && data.companyId !== 0
                      ? companies.filter((x) => x.value === data.companyId)[0]
                          .label
                      : "",
                  value: companies.length !== 0 ? data.companyId : "",
                }}
                isDisabled={true}
              />
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormSubCustomer")}</label>
              <input
                type="text"
                name="subCustomer"
                ref={register({ required: false })}
                className="form-control"
                defaultValue={data.subCustomer}
              />
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isPaid"
                ref={register}
                defaultChecked={data.isPaid}
              />

              <label>{translator("InvoiceFormPaid")}</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isExported"
                ref={register}
                defaultChecked={data.isExported}
              />
              <label>{translator("InvoiceFormExported")}</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isArrived"
                ref={register}
                defaultChecked={data.isArrived}
              />
              <label>{translator("InvoiceFormArrived")}</label>
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormPaymentType")}</label>
              <Controller
                as={Select}
                control={control}
                name="paymentType"
                rules={{ required: false }}
                placeholder={translator("DropdownOption")}
                options={paymentTypes}
                defaultValue={{
                  label:
                    paymentTypes.length && data.paymentType !== null
                      ? paymentTypes.filter(
                          (x) => x.value === data.paymentType
                        )[0].label
                      : "",
                  value: data.paymentType,
                }}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div
              className="container-fluid"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => history.goBack()}
              >
                {translator("ButtonCancel")}
              </button>
              &nbsp;
              {[superadmin, admin].includes(Number(roleId)) ? (
                <button className="btn btn-primary" type="submit">
                  {translator("ButtonSave")}
                </button>
              ) : null}
            </div>
          </div>
          <div className="col-lg-9 border-left">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => exportToPdf(data, items, false)}
            >
              {translator("InvoiceExport")}
            </button>

            <hr />
            <BootstrapTable
              keyField="id"
              data={items.filter((a) => a.isCar === true)}
              columns={columns}
              pagination={paginationFactory()}
              cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
              noDataIndication={translator("NoDataIndication")}
            />
            <hr />
            <BootstrapTable
              keyField="id"
              data={items.filter((a) => a.isCar === false)}
              columns={columns2}
              pagination={paginationFactory()}
              noDataIndication={translator("NoDataIndication")}
              cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
            />
          </div>
        </form>
      )}

      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/invoices")}
      />
    </CardWrap>
  );
};

export default InvoiceUpdate;
