import { dashboardUrl } from "../Config/Url";
import axios from "axios";

export const dashboardService = {
  paidTotal,
  unPaidTotal,
  unPaidAmount,
  carsTotal,
  companyPaidTotal,
  companyPaidAmount,
};

async function paidTotal(from, to) {
  return axios
    .put(`${dashboardUrl}/paidInvoices`, {
      from: from,
      to: to,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function unPaidTotal(from, to) {
  return axios
    .put(`${dashboardUrl}/unpaidInvoices`, {
      from: from,
      to: to,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function unPaidAmount(from, to) {
  return axios
    .put(`${dashboardUrl}/unpaidInvoicesTotal`, {
      from: from,
      to: to,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function carsTotal(from, to) {
  return axios
    .put(`${dashboardUrl}/cars`, {
      from: from,
      to: to,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function companyPaidTotal(from, to) {
  return axios
    .put(`${dashboardUrl}/company/paid`, {
      from: from,
      to: to,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function companyPaidAmount(from, to) {
  return axios
    .put(`${dashboardUrl}/company/PaidAmount`, {
      from: from,
      to: to,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
