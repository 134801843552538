import { carImageUrl } from "../Config/Url";
import axios from "axios";

export const carImageService = {
  getCarImages,
  deleteCarImage,
  addCarImage,
};

async function getCarImages(id) {
  return axios
    .get(`${carImageUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteCarImage(id) {
  return axios
    .delete(`${carImageUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addCarImage(data) {
  var formData = new FormData();
  data.images.forEach((element) => {
    formData.append("Images", element);
  });

  return axios
    .post(carImageUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}
