import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { carDocumentService } from "../../Services/CarDocumentService";

import { useForm, Controller } from "react-hook-form";
const DocumentModal = ({ show, handleHide, carId, translator }) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  //const [update, setUpdate] = useState(new Date());
  const onSubmit = (data) => {
    console.log("RR", data);
    //return;
    carDocumentService
      .addCarDocument(carId, data)
      .then((res) => handleHide)
      .catch((err) => console.log(err));
  };
  return (
    <Modal size="lg" show={show} onHide={handleHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{translator("DocumentOverview")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormDocuments")}</label>
              <input
                className="form-control"
                type="file"
                name="documents"
                ref={register()}
                multiple
              />
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleHide}
        >
          {translator("ButtonCancel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentModal;
