import { useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { GlobalContext } from "../../Contexts/GlobalContext";
import Toolbar from "./Toolbar";

const Layout = (props) => {
  const { isActiveMenu, updatedResource, selectedLanguageId } =
    useContext(GlobalContext);

  return (
    <>
      <Header />
      <Sidebar />
      <Toolbar />
      <div
        className="fluid p-4"
        id={isActiveMenu ? "content-wrap" : "content-wrap-full"}
      >
        {props.children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
