import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { companyService } from "../../Services/CompanyService";
import { userService } from "../../Services/UserService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import ResultModal from "../Common/ResultModal";

const UserUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const options = [
    //{ value: 1, label: "Superadmin" },
    { value: 2, label: "Admin" },
    { value: 3, label: "Company" },
  ];

  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });

  useEffect(() => {
    Promise.all([
      userService.getUserById(match.params.id),
      companyService.getCompanies(),
    ])
      .then((res) => {
        setUser(res[0].data.data);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const onSubmit = (data) => {
    userService
      .updateUser({
        id: match.params.id,
        //companyId: Number(data.companyId.value),
        fullName: data.fullName,
        //roleId: Number(data.role.value),
        printName: data.printName,
      })
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("UserUpdateHeader")}>
      {companies.length === 0 || user === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label> {translator("UserFormFullName")}</label>
              <input
                name="fullName"
                className="form-control"
                type="text"
                defaultValue={user.fullName}
                ref={register({ required: true })}
              />
              {errors.fullName && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label> {translator("UserFormUsername")}</label>
              <input
                name="username"
                className="form-control"
                type="text"
                defaultValue={user.username}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label>{translator("UserFormPassword")}</label>
              <input
                name="password"
                className="form-control"
                type="text"
                defaultValue={user.userPassword}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label> {translator("UserFormPrintName")}</label>
              <input
                name="printName"
                className="form-control"
                type="text"
                defaultValue={user.printName}
                ref={register({ required: true })}
              />
              {errors.printName && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      )}
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/users")}
      />
    </CardWrap>
  );
};

export default UserUpdate;
