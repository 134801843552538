import { documentUrl } from "../Config/Url";
import axios from "axios";

export const documentService = {
  getDocuments,
  deleteDocument,
  addDocument,
  getById,
  updateDocument,
};

async function getDocuments() {
  return axios
    .get(documentUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteDocument(id) {
  return axios
    .delete(`${documentUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getById(id) {
  return axios
    .get(`${documentUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addDocument(data) {
  var formData = new FormData();
  console.log(data);

  formData.append("DocumentNumber", data.documentNumber);
  formData.append("Description", data.description);
  formData.append("CompanyId", Number(data.companyId.value));
  let arrayTest = Array.from(data.files);
  arrayTest.forEach((element) => {
    formData.append("Files", element);
  });

  return axios
    .post(documentUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateDocument(id, data) {
  console.log(data.images);
  var formData = new FormData();

  formData.append("Data.DocumentNumber", data.documentNumber);
  formData.append("Data.Description", data.description);

  let arrayTest = Array.from(data.files);
  arrayTest.forEach((element) => {
    formData.append("Data.Files", element);
  });

  return axios
    .put(`${documentUrl}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}
