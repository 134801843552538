import { Card } from "react-bootstrap";
const CardWrap = ({ children, title }) => {
  return (
    <Card>
      <Card.Header>{title}</Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default CardWrap;
