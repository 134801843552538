import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ResultModal = ({ show, title, text, handleHide, handleSubmit }) => {
  const { t: translator } = useTranslation();

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header>
        <Modal.Title>{translator(title)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{translator(text)}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmit}
        >
          {translator("Submit")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResultModal;
