import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import Select from "react-select";
import { companyService } from "../../Services/CompanyService";
import { documentService } from "../../Services/DocumentService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import ResultModal from "../Common/ResultModal";

const DocumentAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });

  useEffect(() => {
    companyService
      .getCompanies()
      .then((res) =>
        setCompanies(res.data.data.map((i) => ({ value: i.id, label: i.name })))
      )
      .catch((err) => console.log(err.response));
  }, []);

  const onSubmit = (data) => {
    documentService
      .addDocument(data)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("DocumentAddHeader")}>
      {companies.length === 0 ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("DocumentFormNumber")}</label>
              <input
                name="documentNumber"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.name && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("DocumentFormDescription")}</label>
              <input
                name="description"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.regNum && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("DocumentFormCompany")}</label>
              <Controller
                as={Select}
                control={control}
                options={companies}
                name="companyId"
                rules={{ required: true }}
                placeholder={translator("CompanyDropdownOption")}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("DocumentFormAttachment")}</label>
              <input
                className="form-control"
                type="file"
                name="files"
                ref={register()}
                multiple
              />
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      )}
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/documents")}
      />
    </CardWrap>
  );
};

export default DocumentAdd;
