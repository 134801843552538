import axios from "axios";
import { langUrl, resourceUrl } from "../Config/Url";

export const globalService = { getResourcesByLanguageId, getLanguages };

async function getResourcesByLanguageId(languageId) {
  return axios
    .get(`${resourceUrl}/${languageId}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getLanguages() {
  return axios
    .get(langUrl)
    .then((res) => res)
    .catch((err) => err.response);
}
