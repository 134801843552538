import { documentAttUrl } from "../Config/Url";
import axios from "axios";

export const documentAttachmentService = {
  getDocumentAttachments,
  deleteDocumentAttachment,
  addDocumentAttachment,
};

async function getDocumentAttachments(id) {
  return axios
    .get(`${documentAttUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteDocumentAttachment(id) {
  return axios
    .delete(`${documentAttUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addDocumentAttachment(data) {
  var formData = new FormData();
  data.images.forEach((element) => {
    formData.append("Files", element);
  });

  return axios
    .post(documentAttUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}
