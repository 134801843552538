import { useEffect, useState, useContext } from "react";
import { obligationService } from "../../Services/ObligationService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import * as dateHelper from "../../Helpers/DateHelper";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../Contexts/GlobalContext";

const ObligationList = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [obligations, setObligations] = useState([]);
  const { isMobile } = useContext(GlobalContext);

  useEffect(() => {
    obligationService
      .getObligations()
      .then((res) => {
        setObligations(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  function dateFormatter(cell, row) {
    return dateHelper.formatUtcToDate(row.date);
  }

  const handleToAdd = () => {
    history.push("/add-obligation");
  };

  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      history.push(`/update-obligation/${row.id}`);
    },
  };

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "date",
      text: `${translator("ObligationTableDate")}`,
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "companyName",
      text: `${translator("ObligationTableCompany")}`,
      sort: true,
    },
    {
      dataField: "totalAmount",
      text: `${translator("ObligationTableAmount")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
  ];

  return (
    <CardWrap title={translator("ObligationOverview")}>
      <ToolkitProvider
        keyField="id"
        data={obligations}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <SearchBar
              {...props.searchProps}
              placeholder={translator("Search")}
            />
            <span style={{ float: "right" }}>
              <Button variant="primary" onClick={handleToAdd}>
                {isMobile ? (
                  <i className="pe-7s-plus"></i>
                ) : (
                  translator("ObligationAddButton")
                )}
              </Button>
            </span>

            <hr />
            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("ObligationTableAmount")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
    </CardWrap>
  );
};

export default ObligationList;
