import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ErrorMsg from "../Common/ErrorMsg";

const NewItemModal = ({ show, handleHide, handleModalSubmit, translator }) => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [isCar, setIsCar] = useState(true);

  return (
    <Modal size="lg" show={show} onHide={handleHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{translator("NewItem")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form
          onSubmit={handleSubmit(handleModalSubmit)}
          className="row pl-4 pr-4"
        >
          <div className="col-12">
            <div className="form-group">
              <input
                type="checkbox"
                name="isCar"
                ref={register}
                defaultChecked={isCar}
                onChange={() => {
                  setIsCar(!isCar);
                  reset();
                }}
              />
              &nbsp;
              <label>{translator("IsCar")}</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>{translator("ComodityCode")}</label>
              <input
                className="form-control"
                type="text"
                name="comodityCode"
                ref={register({ required: false })}
              />
              {errors.comodityCode && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            {isCar ? (
              <>
                <div className="form-group">
                  <div className="form-group">
                    <input type="checkbox" name="halfCar" ref={register} />
                    &nbsp;
                    <label> {translator("halfCar")}</label>
                  </div>
                </div>
                <div className="form-group">
                  <label>{translator("Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    ref={register({ required: false })}
                  />
                  {errors.name && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
                <div className="form-group">
                  <label>{translator("RegNum")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="regNum"
                    ref={register({ required: false })}
                  />
                  {errors.regNum && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
                <div className="form-group">
                  <label>{translator("ChasisNum")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="chasisNum"
                    ref={register({ required: false })}
                  />
                  {errors.chasisNum && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
                <div className="form-group">
                  <label>{translator("EngineNum")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="engineNum"
                    ref={register({ required: false })}
                  />
                  {errors.engineNum && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label>{translator("PartNumber")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="partNumber"
                    ref={register({ required: false })}
                  />
                  {errors.partNumber && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
                <div className="form-group">
                  <label>{translator("ReferenceNumber")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="referenceNumber"
                    ref={register({ required: false })}
                  />
                  {errors.referenceNumber && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
                <div className="form-group">
                  <label>{translator("Description")}</label>
                  <textarea
                    className="form-control"
                    type="text"
                    name="description"
                    ref={register({ required: false })}
                  />
                  {errors.description && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-6">
            {isCar && (
              <>
                <div className="form-group">
                  <label>{translator("EngineSize")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="engineSize"
                    ref={register({ required: false })}
                  />
                  {errors.engineSize && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
                <div className="form-group">
                  <label>{translator("kW")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="kw"
                    ref={register({ required: false })}
                  />
                  {errors.kw && <ErrorMsg text={translator("RequiredField")} />}
                </div>
                <div className="form-group">
                  <label>{translator("Color")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="color"
                    ref={register({ required: false })}
                  />
                  {errors.color && (
                    <ErrorMsg text={translator("RequiredField")} />
                  )}
                </div>
              </>
            )}
            <div className="form-group">
              <label>{translator("Amount")}</label>
              <input
                className="form-control"
                type="text"
                name="amount"
                ref={register({ required: true })}
              />
              {errors.amount && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("Quantity")}</label>
              <input
                className="form-control"
                type="text"
                name="quantity"
                ref={register({ required: true })}
              />
              {errors.quantity && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleHide}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default NewItemModal;
