import { useEffect, useState, useContext } from "react";
import { globalCarService } from "../../Services/GlobalCarService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import DeleteModal from "../Common/DeleteModal";
import ResultModal from "../Common/ResultModal";
import ReactDragListView from "react-drag-listview";

const GlobalCarProcessing = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const { isMobile } = useContext(GlobalContext);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [update, setUpdate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState({
    item: null,
    show: false,
  });
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [search, setSearch] = useState({
    companyId: 0,
  });

  useEffect(() => {
    globalCarService
      .getCarsInProcessing()
      .then((res) => {
        setCars(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [update]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = cars;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setCars(data);
      var newList = data.map((i, index) => ({ priority: index, id: i.id }));

      globalCarService
        .changeOrder(newList)
        .then((res) => {
          setUpdate(new Date());
        })
        .catch((err) => console.log(err));
    },
    nodeSelector: "li",
    handleSelector: "li",
  };

  const handleSubmitDeleteModal = () => {
    globalCarService
      .deleteCar(deleteModal.item)
      .then((res) => {
        setDeleteModal({ item: null, show: false });
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            });
      })
      .catch((err) => console.log(err));
  };

  const finish = (id) => {
    globalCarService
      .finishProcessing(id)
      .then((res) => {
        setUpdate(new Date());
      })
      .catch((err) => console.log(err));
  };
  return (
    <CardWrap title={translator("CarReview")}>
      <ReactDragListView {...dragProps}>
        <ol>
          {cars &&
            cars.map((item, index) => (
              <li
                key={index}
                style={{
                  padding: "10px 5px",
                  border: "1px solid #000",
                  width: "75%",
                }}
              >
                <a
                  onClick={() => history.push(`/update-global-car/${item.id}`)}
                  style={{ cursor: "pointer", color: "#336598" }}
                >
                  {item.brand} {item.model} {item.colour} {item.engineCode}{" "}
                  {item.mileage} {item.bodyType} {item.paintCode}
                </a>
                <i
                  className="pe-7s-shuffle"
                  style={{
                    cursor: "pointer",
                    color: "#c00",
                    fontWeight: "600",
                  }}
                ></i>
                <a
                  onClick={() => finish(item.id)}
                  href="#"
                  style={{ float: "right" }}
                >
                  Finish
                </a>
              </li>
            ))}
        </ol>
      </ReactDragListView>
      <DeleteModal
        show={deleteModal.show}
        handleHide={() =>
          setDeleteModal({
            item: null,
            show: false,
          })
        }
        handleSubmit={handleSubmitDeleteModal}
      />
    </CardWrap>
  );
};

export default GlobalCarProcessing;
