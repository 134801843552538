import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [isLogged, setIsLogged] = useState(localStorage.getItem("isLogged"));
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [roleId, setRoleId] = useState(localStorage.getItem("roleId"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;

  useEffect(() => {
    setIsLogged(localStorage.getItem("isLogged"));
    setUsername(localStorage.getItem("username"));
  }, [token]);

  const logout = () => {
    localStorage.setItem("roleId", "");
    localStorage.setItem("isLogged", false);
    setIsLogged(false);
    window.location.href = "/auth";
  };

  window.onunload = function () {
    localStorage.setItem("unloadTime", new Date().getTime());
  };

  window.onload = function () {
    var t0 = Number(localStorage.getItem("unloadTime"));
    if (isNaN(t0)) t0 = 0;
    var t1 = new Date().getTime();
    var duration = t1 - t0;
    if (duration > 3 * 1000) {
      logout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        setIsLogged,
        setToken,
        username,
        setUsername,
        logout,
        roleId,
        setRoleId,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
