import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { globalService } from "../../Services/GlobalService";

const Toolbar = () => {
  const { username, logout } = useContext(AuthContext);
  const { selectedLanguageId, changeLanguage } = useContext(GlobalContext);
  const [languages, setLanguages] = useState(null);

  useEffect(() => {
    globalService
      .getLanguages()
      .then((res) => setLanguages(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      className="container-fluid"
      style={{ background: "#f8f8f8", height: 45 }}
    >
      <div
        className="row"
        style={{
          position: "absolute",
          right: 25,
          top: 65,
        }}
      >
        {languages !== null && (
          <div className="dropdown">
            <button
              className="dropdown-toggle text-dark collapsed outline-secondary"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ border: 0, background: "transparent" }}
            >
              {selectedLanguageId &&
                languages &&
                languages.find((l) => l.id == selectedLanguageId).name}
            </button>
            {languages && (
              <div
                className="dropdown-menu nav-dd-menu"
                aria-labelledby="dropdownMenuLink"
              >
                {languages
                  .filter((l) => l.id != selectedLanguageId)
                  .map((lng) => (
                    <button
                      key={lng.id}
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        changeLanguage(lng.id);
                      }}
                    >
                      {lng.name}
                    </button>
                  ))}
              </div>
            )}
          </div>
        )}
        &nbsp;&nbsp;
        <div className="dropdown">
          <button
            className="dropdown-toggle text-dark collapsed outline-secondary"
            id="dropdownMenuLink2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ border: 0, background: "transparent" }}
          >
            {username}
          </button>
          <div
            className="dropdown-menu nav-dd-menu"
            aria-labelledby="dropdownMenuLink2"
          >
            <button className="dropdown-item" onClick={logout}>
              {"Logout"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
