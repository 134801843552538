import { useEffect, useState, useContext } from "react";
import { Container, Row, Card } from "react-bootstrap";
import { dashboardService } from "../../Services/DashboardService";
import DatePickerModal from "../Common/DatePickerModal";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
} from "recharts";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  const [paidInvoicesTotal, setpaidInvoicesTotal] = useState([]);
  const [paidInvoicesAmount, setpaidInvoicesAmount] = useState([]);

  const [paidTotal, setPaidTotal] = useState(0);
  const [unpaidTotal, setUnPaidTotal] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);
  const [carTotal, setCarTotal] = useState(0);
  const [update, setupdate] = useState(new Date());
  const [search, setSearch] = useState({ from: null, to: null });
  const [datePickerModal, setdatePickerModal] = useState({
    show: false,
  });

  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  useEffect(() => {
    setSearch({ from: null, to: null });
  }, []);

  useEffect(() => {
    dashboardService
      .paidTotal(search.from, search.to)
      .then((res) => {
        setPaidTotal(res.data.total);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));

    dashboardService
      .unPaidTotal(search.from, search.to)
      .then((res) => {
        setUnPaidTotal(res.data.total);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));

    dashboardService
      .carsTotal(search.from, search.to)
      .then((res) => {
        setCarTotal(res.data.total);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));

    dashboardService
      .unPaidAmount(search.from, search.to)
      .then((res) => {
        setUnpaidAmount(res.data.total);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));

    // graph
    dashboardService
      .companyPaidTotal(search.from, search.to)
      .then((res) => {
        setpaidInvoicesTotal(res.data.data);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));

    dashboardService
      .companyPaidAmount(search.from, search.to)
      .then((res) => {
        setpaidInvoicesAmount(res.data.data);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [update]);

  const handleSubmit = (data) => {
    //setSearch({});
    console.log(data);
  };

  const openModal = () => {
    setdatePickerModal({ show: true });
  };

  return (
    <Container fluid>
      <Row>
        <div className="col-lg-2">
          <p
            className="card-dash-category blue-color text-up"
            style={{
              marginTop: 5,
              textAlign: "left",
              fontSize: 25,
              color: "#000",
            }}
          >
            Dashboard
          </p>
        </div>
        <div className="col-lg-8">
          <p
            className="card-dash-category blue-color text-up"
            style={{
              marginTop: 5,
              textAlign: "center",
              fontSize: 25,
              color: "#000",
            }}
          ></p>
        </div>
        <div className="col-lg-2">
          <p
            className="card-dash-category blue-color "
            style={{
              marginTop: 5,
              textAlign: "right",
              fontSize: 25,
              color: "#000",
            }}
          >
            <i className="pe-7s-date" onClick={openModal}></i>
          </p>
        </div>
      </Row>
      <Row>
        <div className="col-lg-3">
          <div className="card-dash blue-backgorund">
            <div className="card-dash-body ">
              <div className="row">
                <div className="col-5 col-md-4">
                  <div className="icon-big text-center white-color">
                    <i className="pe-7s-note2"></i>
                  </div>
                </div>
                <div className="col-7 col-md-8">
                  <div className="numbers">
                    <p
                      className="card-dash-category white-color text-up"
                      style={{
                        marginTop: 35,
                        textAlign: "center",
                        fontSize: 25,
                      }}
                    >
                      Paid invoices
                    </p>
                    <p
                      className="card-dash-title white-color big-text"
                      style={{ float: "right", marginRight: 50 }}
                    >
                      {paidTotal}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card-dash white-backgorund">
            <div className="card-dash-body">
              <div className="row">
                <div className="col-5 col-md-4">
                  <div className="icon-big text-center blue-color">
                    <i className="pe-7s-note2"></i>
                  </div>
                </div>
                <div className="col-7 col-md-8">
                  <NavLink className="menu-item" to="/invoices?unpaid">
                    <div className="numbers">
                      <p
                        className="card-dash-category blue-color text-up"
                        style={{
                          marginTop: 35,
                          textAlign: "center",
                          fontSize: 25,
                        }}
                      >
                        Unpaid invoices
                      </p>
                      <p
                        className="card-dash-title blue-color big-text"
                        style={{ float: "right", marginRight: 50 }}
                        handleClick={() => alert("clikcl")}
                      >
                        <span>{unpaidTotal}</span>
                      </p>
                      <p></p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card-dash blue-backgorund">
            <div className="card-dash-body ">
              <div className="row">
                <div className="col-5 col-md-4">
                  <div className="icon-big text-center white-color">
                    <i className="pe-7s-cash"></i>
                  </div>
                </div>
                <div className="col-7 col-md-8">
                  <div className="numbers">
                    <p
                      className="card-dash-category white-color text-up"
                      style={{
                        marginTop: 35,
                        textAlign: "center",
                        fontSize: 25,
                      }}
                    >
                      Unpaid amount
                    </p>
                    <p
                      className="card-dash-title white-color big-text"
                      style={{ float: "right", marginRight: 50 }}
                    >
                      {new Intl.NumberFormat("en", {
                        style: "currency",
                        currency: "GBP",
                      }).format(Number(unpaidAmount))}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="card-dash white-backgorund">
            <div className="card-dash-body ">
              <div className="row">
                <div className="col-5 col-md-4">
                  <div className="icon-big text-center blue-color">
                    <i className="pe-7s-car"></i>
                  </div>
                </div>
                <div className="col-7 col-md-8">
                  <div className="numbers">
                    <p
                      className="card-dash-category blue-color text-up"
                      style={{
                        marginTop: 35,
                        textAlign: "center",
                        fontSize: 25,
                      }}
                    >
                      Cars
                    </p>
                    <p
                      className="card-dash-title blue-color big-text"
                      style={{ float: "right", marginRight: 50 }}
                    >
                      {carTotal}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      {[superadmin, admin].includes(Number(roleId)) && (
        <Row>
          <div className="col-lg-5 mt-5">
            <h3>
              <i className="pe-7s-note2"></i> Invoice per customer payed (count)
            </h3>
            <hr />
            <BarChart width={600} height={300} data={paidInvoicesTotal}>
              <XAxis dataKey="name" stroke="#336598" />
              <YAxis />
              <Tooltip />

              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Bar dataKey="uv" fill="#336598" barSize={30} />
            </BarChart>
          </div>
          <div className="col-lg-5 mt-5">
            <h3>
              <i className="pe-7s-cash"></i> Invoice per customer payed (amount)
            </h3>
            <hr />
            <BarChart width={600} height={300} data={paidInvoicesAmount}>
              <XAxis dataKey="name" stroke="#336598" />
              <YAxis />
              <Tooltip />

              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <Bar dataKey="uv" fill="#336598" barSize={30} />
            </BarChart>
          </div>
        </Row>
      )}

      <DatePickerModal
        show={datePickerModal.show}
        handleHide={() =>
          setdatePickerModal({
            show: false,
          })
        }
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

export default Dashboard;
