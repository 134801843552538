import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";

import { globalCarService } from "../../Services/GlobalCarService";
import { globalCarImageService } from "../../Services/GlobalCarImageService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import DztImageGalleryComponent from "reactjs-image-gallery";
import { staticUrl } from "../../Config/Url";
import ResultModal from "../Common/ResultModal";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import ImageModal from "./ImageModal";
import DatePicker from "react-datepicker";

const CarUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState([]);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [update, setUpdate] = useState(new Date());

  const [show, setShow] = useState(false);
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [imageModal, setImageModal] = useState(false);

  const handleHide = () => {
    setShow(false);
  };
  useEffect(() => {
    Promise.all([
      globalCarService.getById(match.params.id),
      globalCarImageService.getCarImages(match.params.id),
    ])
      .then((res) => {
        console.log("car", res[0].data.data);
        setData(res[0].data.data);

        // console.log(res[2].data.data);
        setGallery(
          res[1].data.data.map((i) => ({
            url: `${staticUrl}/images/${i.name}`,
            title: i.name,
            thumbUrl: `${staticUrl}/images/${i.name}`,
          }))
        );
        setImages(res[1].data.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id, update]);

  const onSubmit = (data) => {
    globalCarService
      .updateCar(match.params.id, data)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CarUpdateHeader")}>
      {data === null ? (
        <Loader />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label>{translator("GlobalCarFormDate")}</label>
                <Controller
                  as={DatePicker}
                  control={control}
                  className="form-control"
                  valueName="selected"
                  autocomplete="off"
                  dateFormat="dd.MM.yyyy"
                  name="datePurchased"
                  rules={{ required: true }}
                  defaultValue={new Date(data.datePurchased)}
                />
                {errors.datePurchased && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarPruchasedFrom")}</label>
                <input
                  name="pruchasedFrom"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.pruchasedFrom}
                />
                {errors.regNum && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormlotNumber")}</label>
                <input
                  name="lotNumber"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.lotNumber}
                />
                {errors.chasisNum && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormRegNumber")}</label>
                <input
                  name="regNumber"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.regNumber}
                />
                {errors.engineNum && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label>{translator("GlobalCarFormBrand")}</label>
                <input
                  name="brand"
                  className="form-control"
                  type="text"
                  ref={register({ required: true })}
                  defaultValue={data.brand}
                />
                {errors.engineSize && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormModel")}</label>
                <input
                  className="form-control"
                  type="text"
                  name="model"
                  ref={register({ required: true })}
                  defaultValue={data.model}
                />
                {errors.kw && <ErrorMsg text={translator("RequiredField")} />}
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormColor")}</label>
                <input
                  className="form-control"
                  type="text"
                  name="colour"
                  ref={register({ required: true })}
                  defaultValue={data.colour}
                />
                {errors.color && (
                  <ErrorMsg text={translator("RequiredField")} />
                )}
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormEngineCode")}</label>
                <input
                  type="text"
                  name="engineCode"
                  ref={register()}
                  className="form-control"
                  defaultValue={data.engineCode}
                />
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormMileage")}</label>
                <input
                  type="text"
                  name="mileage"
                  ref={register()}
                  className="form-control"
                  defaultValue={data.mileage}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label>{translator("GlobalCarFormBodyType")}</label>
                <input
                  type="text"
                  name="bodyType"
                  ref={register()}
                  className="form-control"
                  defaultValue={data.bodyType}
                />
              </div>
              <div className="form-group">
                <label>{translator("GlobalCarFormPaintCode")}</label>
                <input
                  type="text"
                  name="paintCode"
                  ref={register()}
                  className="form-control"
                  defaultValue={data.paintCode}
                />
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  name="isArrived"
                  ref={register}
                  defaultChecked={data.isArrived}
                />
                <label>{translator("InvoiceFormArrived")}</label>
              </div>
              <div className="form-group">
                <label>{translator("CarFormImages")}</label>
                <input
                  className="form-control"
                  type="file"
                  name="images"
                  ref={register()}
                  multiple
                />
              </div>
            </div>
            <div
              className="container-fluid"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => history.goBack()}
              >
                {translator("ButtonCancel")}
              </button>
              &nbsp;
              <button className="btn btn-primary" type="submit">
                {translator("ButtonSave")}
              </button>
            </div>
          </form>

          <div className="row">
            {gallery.length === 0 || data === null ? (
              ""
            ) : (
              <>
                <div className="col-md-12">
                  <h5>{translator("CarGallery")}</h5>
                  {[superadmin, admin].includes(Number(roleId)) && (
                    <button
                      className="btn btn-primary"
                      onClick={() => setImageModal(true)}
                    >
                      {translator("CarImageManage")}
                    </button>
                  )}

                  <DztImageGalleryComponent images={gallery} />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {images.length > 0 && (
        <ImageModal
          images={images}
          show={imageModal}
          translator={translator}
          handleHide={() => {
            setImageModal(false);
            setUpdate(new Date());
          }}
        />
      )}

      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/global-cars")}
      />
    </CardWrap>
  );
};

export default CarUpdate;
