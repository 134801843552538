import { userUrl } from "../Config/Url";
import axios from "axios";

export const userService = {
  getUsers,
  getUserById,
  deleteUser,
  addUser,
  updateUser,
};

async function getUsers() {
  return axios
    .get(userUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getUserById(id) {
  return axios
    .get(`${userUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteUser(id) {
  return axios
    .delete(`${userUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addUser({
  username,
  password,
  companyId,
  fullName,
  roleId,
  printName,
}) {
  return axios
    .post(userUrl, {
      username,
      password,
      companyId,
      fullName,
      roleId,
      printName,
      allowedLogin: true,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateUser({ id, fullName, printName }) {
  return axios
    .put(`${userUrl}/${id}`, {
      //companyId,
      fullName,
      //roleId,
      printName,
      allowedLogin: true,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
