import { authUrl } from "../Config/Url";
import axios from "axios";

export const authService = {
  login,
  changePassword,
};

async function login(userName, password) {
  return axios
    .post(`${authUrl}/login`, { userName, password })
    .then((res) => res)
    .catch((err) => err.response);
}

async function changePassword(oldPassword, newPassword, confirmPassword) {
  return axios
    .post(`${authUrl}/changePassword`, {
      oldPassword,
      newPassword,
      confirmPassword,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
