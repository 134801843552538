import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import Select from "react-select";
import { companyService } from "../../Services/CompanyService";
import { carService } from "../../Services/CarService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import ResultModal from "../Common/ResultModal";

const CarAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });

  useEffect(() => {
    companyService
      .getCompanies()
      .then((res) =>
        setCompanies(res.data.data.map((i) => ({ value: i.id, label: i.name })))
      )
      .catch((err) => console.log(err.response));
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    carService
      .addCar(data)
      .then((res) =>
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            })
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CarAddHeader")}>
      {companies.length === 0 ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormName")}</label>
              <input
                name="name"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.name && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CarFormRegNum")}</label>
              <input
                name="regNum"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.regNum && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CarFormChasisNum")}</label>
              <input
                name="chasisNum"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.chasisNum && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("CarFormEngineNum")}</label>
              <input
                name="engineNum"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.engineNum && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("CarFormEngineSize")}</label>
              <input
                name="engineSize"
                className="form-control"
                type="text"
                ref={register({ required: true })}
              />
              {errors.engineSize && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormKw")}</label>
              <input
                className="form-control"
                type="text"
                name="kw"
                ref={register({ required: true })}
              />
              {errors.kw && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CarFormColor")}</label>
              <input
                className="form-control"
                type="text"
                name="color"
                ref={register({ required: true })}
              />
              {errors.color && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CarFormCompany")}</label>
              <Controller
                as={Select}
                control={control}
                options={companies}
                name="companyId"
                rules={{ required: true }}
                placeholder={translator("CompanyDropdownOption")}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormSubCustomer")}</label>
              <input
                type="text"
                name="subCustomer"
                ref={register()}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>{translator("CarFormLotNumber")}</label>
              <input
                type="text"
                name="lotNumber"
                ref={register()}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormImages")}</label>
              <input
                className="form-control"
                type="file"
                name="images"
                ref={register()}
                multiple
              />
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isPaid"
                ref={register}
                //defaultChecked={data.isPaid}
              />

              <label>{translator("InvoiceFormPaid")}</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isExported"
                ref={register}
                //defaultChecked={data.isExported}
              />
              <label>{translator("InvoiceFormExported")}</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isArrived"
                ref={register}
                //defaultChecked={data.isArrived}
              />
              <label>{translator("InvoiceFormArrived")}</label>
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      )}
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/cars")}
      />
    </CardWrap>
  );
};

export default CarAdd;
