import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export async function i18nService(transData) {
  return i18n.use(initReactI18next).init({
    resources: {
      trans: {
        translations: transData,
      },
    },
    /* default languageIduage when load the website in browser */
    lng: "trans",
    /* When react i18next not finding any languageIduage to as default in borwser */
    fallbackLng: "trans",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    missingKeyHandler: (
      lng,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options
    ) => {
      // testing especially one key
      console.log(
        "Missing key: Language: ",
        lng,
        ", Namespace: ",
        ns,
        ", Key: ",
        key
      );
    },
    saveMissing: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });
}
