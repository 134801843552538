import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { email } from "../../Config/Regex";
import { companyService } from "../../Services/CompanyService";
import { useTranslation } from "react-i18next";
import ResultModal from "../Common/ResultModal";

const CompanyAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });

  const onSubmit = (data) => {
    companyService
      .addCompany({
        name: data.name,
        address: data.address,
        city: data.city,
        postNumber: data.postNumber,
        customerCode: data.customerCode,
        email: data.email,
        phone: data.phone,
        userName: data.userName,
        password: data.password,
        vat: data.vat,
        allowedLogin: data.allowedLogin,
        autoSendMail: data.autoSendMail,
        country: data.country,
      })
      .then((res) => {
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            });
      })
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CompanyAddHeader")}>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-lg-4">
          <div className="form-group">
            <label>{translator("ComapnyFormName")}</label>
            <input
              type="text"
              name="name"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.name && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("ComapnyFormUsername")}</label>
            <input
              type="text"
              name="userName"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.userName && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("ComapnyFormPassword")}</label>
            <input
              type="password"
              name="password"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.password && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("CompanyFormEmail")}</label>
            <input
              type="text"
              name="email"
              className="form-control"
              ref={register({ required: true, pattern: email })}
            />
            {errors.email && errors.email.type === "required" && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
            {errors.email && errors.email.type === "pattern" && (
              <ErrorMsg text={translator("ValidEmail")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("CompanyFormPhone")}</label>
            <input
              type="text"
              name="phone"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.phone && <ErrorMsg text={translator("RequiredField")} />}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label>{translator("CompanyFormCity")}</label>
            <input
              type="text"
              name="city"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.city && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("CompanyFormAddress")}</label>
            <input
              type="text"
              name="address"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.address && <ErrorMsg text={translator("RequiredField")} />}
          </div>
          <div className="form-group">
            <label>{translator("CompanyFormPostNumber")}</label>
            <input
              type="text"
              name="postNumber"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.postNumber && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("CompanyFormCode")}</label>
            <input
              type="text"
              name="customerCode"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.customerCode && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("CompanyFormState")}</label>
            <input
              type="text"
              name="country"
              className="form-control"
              ref={register({ required: true })}
            />
            {errors.country && <ErrorMsg text={translator("RequiredField")} />}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <input type="checkbox" name="vat" ref={register} />

            <label> {translator("CompanyFormVat")}</label>
          </div>
          <div className="form-group">
            <input type="checkbox" name="allowedLogin" ref={register} />
            <label> {translator("CompanyFormAllowedLogin")}</label>
          </div>
          <div className="form-group">
            <input type="checkbox" name="autoSendMail" ref={register} />

            <label> {translator("CompanyFormAutoEmail")}</label>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => history.goBack()}
          >
            {translator("ButtonCancel")}
          </button>
          &nbsp;
          <button className="btn btn-primary" type="submit">
            {translator("ButtonSave")}
          </button>
        </div>
      </form>
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => history.push("/companies")}
      />
    </CardWrap>
  );
};

export default CompanyAdd;
