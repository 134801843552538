import { useContext } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { BsJustify, BsX } from "react-icons/bs";

const Header = () => {
  const { isActiveMenu, toggleMenu } = useContext(GlobalContext);
  return (
    <Navbar expand="lg" className="brand-color">
      <span className="menu-toggler " onClick={toggleMenu}>
        {isActiveMenu ? (
          <BsX className="text-white" />
        ) : (
          <BsJustify className="text-white" />
        )}
      </span>
      <Navbar.Brand>
        <NavLink className="navbar-link" to="/">
          Global Auto Salvage
        </NavLink>
      </Navbar.Brand>
    </Navbar>
  );
};

export default Header;
