import { invoiceUrl } from "../Config/Url";
import axios from "axios";

export const invoiceService = {
  getInvoices,
  deleteInvoice,
  addInvoice,
  getInvoice,
  updateInvoice,
  sendMail,
  getInvoiceNumber,
  addExternalInvoice,
};

async function getInvoices(
  companyId,
  from,
  to,
  isPaid,
  isExported,
  isArrived,
  isUnPaid
) {
  //var url=
  return axios
    .get(
      `${invoiceUrl}?companyId=${companyId}&from=${from}&to=${to}&isPaid=${
        isPaid ? isPaid : isUnPaid
      }&isExported=${isExported ? isExported : ""}&isArrived=${
        isArrived ? isArrived : ""
      }`
    )
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteInvoice(id) {
  return axios
    .delete(`${invoiceUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addInvoice(submitData, data) {
  console.log(submitData);

  //return;
  return axios
    .post(invoiceUrl, {
      invoiceNumber: submitData.invoiceNumber,
      invoiceDate: new Date(submitData.invoiceDate),
      companyId:
        submitData.companyId == undefined
          ? null
          : Number(submitData.companyId.value),
      userId: Number(localStorage.getItem("userId")),
      subcustomer: submitData.subcustomer,
      companyAddress: submitData.companyAddress,
      companyName: submitData.companyName,
      totalAmount: data.reduce(
        (sum, { amount, quantity }) => sum + amount * quantity,
        0
      ),
      isPaid: false,
      isArrived: false,
      isExported: false,
      data,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function addExternalInvoice(data, carId) {
  return axios
    .post(`${invoiceUrl}/external`, {
      invoiceNumber: data.invoiceNumber,
      invoiceDate: new Date(data.invoiceDate),
      amount: data.amount,
      quantity: data.quantity,
      paymentType: data.paymentType.value,
      carId: carId,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function getInvoice(id) {
  return axios
    .get(`${invoiceUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateInvoice(id, submitData, items) {
  console.log("i", items);
  return axios
    .put(`${invoiceUrl}/${id}`, {
      invoiceNumber: submitData.invoiceNumber,
      invoiceDate: new Date(submitData.invoiceDate),
      subCustomer: submitData.subCustomer,
      isPaid: submitData.isPaid,
      isArrived: submitData.isArrived,
      isExported: submitData.isExported,
      paymentType: submitData.paymentType.value,
      totalAmount: 0,
      items: items,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function sendMail(id, encodedDocument) {
  return axios
    .put(`${invoiceUrl}/${id}/sendMail`, {
      FileEncoded: encodedDocument,
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function getInvoiceNumber(id) {
  return axios
    .get(`${invoiceUrl}/getInvoiceNumber`)
    .then((res) => res)
    .catch((err) => err.response);
}
