import React from "react";
import { Modal } from "react-bootstrap";

import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

const DatePickerModal = ({ show, handleHide, handleSubmit }) => {
  const { control } = useForm({});

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header>
        <Modal.Title>Period</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <row className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Date from</label>
              <Controller
                as={DatePicker}
                className="form-control"
                control={control}
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="from"
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Date to</label>
              <Controller
                as={DatePicker}
                control={control}
                className="form-control"
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="to"
                rules={{ required: true }}
              />
            </div>
          </div>
        </row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleHide}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmit(1)}
        >
          Search
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DatePickerModal;
