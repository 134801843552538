import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import ErrorMsg from "../Common/ErrorMsg";
import { invoiceService } from "../../Services/InvoiceService";
import DatePicker from "react-datepicker";
import Select from "react-select";

const paymentTypes = [
  { value: "Credit", label: "Credit" },
  { value: "Card", label: "Card" },
  { value: "Bank transfer", label: "Bank transfer" },
];
const NewInvoiceModal = ({
  show,
  handleHide,
  handleModalSubmit,
  translator,
}) => {
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [invoiceNum, setInvoiceNum] = useState(null);

  useEffect(() => {
    invoiceService
      .getInvoiceNumber()
      .then((res) => {
        console.log(res.data);
        setInvoiceNum(res.data);
      })
      .catch((err) => console.log(err.response));
  }, []);

  return (
    <Modal size="md" show={show} onHide={handleHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{translator("InvoiceAddHeader")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form
          onSubmit={handleSubmit(handleModalSubmit)}
          className="row pl-4 pr-4"
        >
          <div className="col-lg-12">
            <div className="form-group">
              <label>{translator("InvoiceFormNumber")}</label>
              {invoiceNum && (
                <input
                  type="text"
                  name="invoiceNumber"
                  ref={register({ required: true })}
                  className="form-control"
                  defaultValue={invoiceNum}
                />
              )}

              {errors.invoiceNumber && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormDate")}</label>
              <Controller
                as={DatePicker}
                control={control}
                className="form-control"
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="invoiceDate"
                rules={{ required: true }}
              />
              {errors.invoiceDate && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("Amount")}</label>
              <input
                className="form-control"
                type="text"
                name="amount"
                ref={register({ required: true })}
              />
              {errors.amount && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("Quantity")}</label>
              <input
                className="form-control"
                type="text"
                name="quantity"
                ref={register({ required: true })}
              />
              {errors.quantity && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormPaymentType")}</label>
              <Controller
                as={Select}
                control={control}
                name="paymentType"
                rules={{ required: false }}
                placeholder={translator("DropdownOption")}
                options={paymentTypes}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleHide}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default NewInvoiceModal;
